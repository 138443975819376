import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string } from 'prop-types';
import Joyride, { STATUS } from 'react-joyride';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import unionWith from 'lodash/unionWith';
import classNames from 'classnames';

import config from '../../config';
import routeConfiguration from '../../routeConfiguration';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import { parse, stringify } from '../../util/urlHelpers';
import { propTypes } from '../../util/types';

import { Page, Loader } from '../../components';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from './SearchPage.helpers';
import { TopbarContainer } from '../../containers';
import MainPanel from './MainPanel';

import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import { searchMapListings, setActiveListing } from './SearchPage.duck';

import css from './SearchPage.module.css';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout
const SEARCH_WITH_MAP_DEBOUNCE = 300; // Little bit of debounce before search is initiated.

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchMapOpenOnMobile: props.tab === 'map',
      isMobileModalOpen: false,
      steps: [
        {
          content: 'Welcome to Wholesale It. The marketplace for car dealers.',
          placement: 'center',
          target: 'body',
          disableOverlayClose: true,
          hideCloseButton: true,
          styles: {
            options: {
              primaryColor: '#329bdf',
              spotlightShadow: 'none',
            },
          },
        },
        {
          content: 'Search by location.',
          spotlightPadding: 20,
          target: '.searchField',
          title: 'Search',
          disableOverlayClose: true,
          hideCloseButton: true,
          styles: {
            options: {
              primaryColor: '#329bdf',
              spotlightShadow: 'none',
            },
          },
        },
        {
          content: 'Customize your search with filters!',
          placement: 'top',
          target: '.applyfilter',
          title: 'Apply filters',
          disableOverlayClose: true,
          hideCloseButton: true,
          styles: {
            options: {
              primaryColor: '#329bdf',
              spotlightShadow: 'none',
            },
            tooltip: {
              borderRadius: 0,
            },
          },
        },
        {
          content: 'Add new listing.',
          placement: 'top',
          target: '.addlistingBtn',
          title: 'Car Listings',
          disableOverlayClose: true,
          hideCloseButton: true,
          styles: {
            options: {
              primaryColor: '#329bdf',
              spotlightShadow: 'none',
            },
          },
        },
        {
          content: (
            <>
              <h2 className={css.joyrideHeading}>
                To list cars/bid/buy, you need to verify the following details:
              </h2>
              <ol className={css.joyrideOrderedlist}>
                <li>Verify email</li>
                {/* <li>Connect and verify stripe account</li> */}
                <li>Add payment method</li>
              </ol>
            </>
          ),
          placement: 'top',
          target: '.addlistingBtn',
          title: 'Verifications',
          disableOverlayClose: true,
          hideCloseButton: true,
          styles: {
            options: {
              primaryColor: '#329bdf',
              spotlightShadow: 'none',
            },
          },
        },
        {
          content: 'View listed cars.',
          placement: 'top',
          target: '.listingcard',
          title: 'Car listings',
          disableOverlayClose: true,
          hideCloseButton: true,
          styles: {
            options: {
              primaryColor: '#329bdf',
              spotlightShadow: 'none',
            },
          },
        },
      ],
    };

    this.searchMapListingsInProgress = false;

    this.onMapMoveEnd = debounce(this.onMapMoveEnd.bind(this), SEARCH_WITH_MAP_DEBOUNCE);
    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);
  }

  // Callback to determine if new search is needed
  // when map is moved by user or viewport has changed
  onMapMoveEnd(viewportBoundsChanged, data) {
    const { viewportBounds, viewportCenter } = data;

    const routes = routeConfiguration();
    const searchPagePath = pathByRouteName('SearchPage', routes);
    const currentPath =
      typeof window !== 'undefined' && window.location && window.location.pathname;

    // When using the ReusableMapContainer onMapMoveEnd can fire from other pages than SearchPage too
    const isSearchPage = currentPath === searchPagePath;

    // If mapSearch url param is given
    // or original location search is rendered once,
    // we start to react to "mapmoveend" events by generating new searches
    // (i.e. 'moveend' event in Mapbox and 'bounds_changed' in Google Maps)
    if (viewportBoundsChanged && isSearchPage) {
      const { history, location, filterConfig } = this.props;

      // parse query parameters, including a custom attribute named category
      const { address, bounds, mapSearch, ...rest } = parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });

      //const viewportMapCenter = SearchMap.getMapCenter(map);
      const originMaybe = config.sortSearchByDistance ? { origin: viewportCenter } : {};

      const searchParams = {
        address,
        ...originMaybe,
        bounds: viewportBounds,
        mapSearch: true,
        ...validFilterParams(rest, filterConfig),
      };

      history.push(createResourceLocatorString('SearchPage', routes, {}, searchParams));
    }
  }
  handleJoyrideCallback = data => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      const updateData = {
        publicData: {
          isTutorialViewed: true,
        },
      };
      this.props.onUpdateProfile(updateData);
    }
  };

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  render() {
    const {
      intl,
      listings,
      filterConfig,
      sortConfig,
      history,
      location,
      mapListings,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      activeListingId,
      onActivateListing,
      isAuthenticated,
      currentUser,
    } = this.props;
    // eslint-disable-next-line no-unused-vars
    const { mapSearch, page, ...searchInURL } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });

    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.sortSearchByDistance)
    const urlQueryParams = pickSearchParamsOnly(searchInURL, filterConfig, sortConfig);

    // Page transition might initially use values from previous search
    const urlQueryString = stringify(urlQueryParams);
    const paramsQueryString = stringify(
      pickSearchParamsOnly(searchParams, filterConfig, sortConfig)
    );
    const searchParamsAreInSync = urlQueryString === paramsQueryString;

    const validQueryParams = validURLParamsForExtendedData(searchInURL, filterConfig);

    const isWindowDefined = typeof window !== 'undefined';
    const isMobileLayout = isWindowDefined && window.innerWidth < MODAL_BREAKPOINT;
    const shouldShowSearchMap =
      !isMobileLayout || (isMobileLayout && this.state.isSearchMapOpenOnMobile);

    const onMapIconClick = () => {
      this.useLocationSearchBounds = true;
      this.setState({ isSearchMapOpenOnMobile: true });
    };

    const { address, bounds, origin } = searchInURL || {};
    const { title, description, schema } = createSearchResultSchema(listings, address, intl);

    const queryParamsExist =
      validQueryParams &&
      typeof validQueryParams === 'object' &&
      Object.values(validQueryParams).length > 0
        ? true
        : false;

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    const pathname = this.props.history.location.pathname;
    const { steps } = this.state;
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        {pathname === '/dashboard' ? null : (
          <React.Fragment>
            {currentUser?.attributes?.profile?.publicData ? (
              <Joyride
                callback={this.handleJoyrideCallback}
                continuous={true}
                run={!currentUser?.attributes?.profile?.publicData?.isTutorialViewed}
                // scrollToFirstStep={true}
                steps={steps}
                styles={{
                  options: {
                    zIndex: 10000,
                  },
                }}
              />
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <TopbarContainer
              className={topbarClasses}
              currentPage="SearchPage"
              currentSearchParams={urlQueryParams}
            />
          </React.Fragment>
        )}

        {!searchInProgress ? (
          <div className={`${css.container} ${pathname === '/dashboard' ? css.paddingUnset : ''}`}>
            {pathname === '/dashboard' ||
            !listings ||
            (Array.isArray(listings) && listings.length === 0 && !queryParamsExist) ? null : (
              <h2 className="page_title" style={{ marginTop: 30 }}>
                <span className={css.filter_title}>Filter</span>
              </h2>
            )}

            <div className="filter_wrapper">
              <MainPanel
                urlQueryParams={validQueryParams}
                queryParamsExist={queryParamsExist}
                listings={listings}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                searchParamsAreInSync={searchParamsAreInSync}
                onActivateListing={onActivateListing}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                onMapIconClick={onMapIconClick}
                pagination={pagination}
                searchParamsForPagination={parse(location.search)}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                isAuthenticated={isAuthenticated}
                currentUser={currentUser}
                history={history}
              />
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  mapListings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
  tab: 'listings',
  filterConfig: config.custom.filters,
  sortConfig: config.custom.sortConfig,
  isAuthenticated: false,
  activeListingId: null,
  currentUser: null,
};

SearchPageComponent.propTypes = {
  onUpdateProfile: func.isRequired,
  listings: array,
  mapListings: array,
  currentUser: object,
  onActivateListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onSearchMapListings: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,
  tab: oneOf(['filters', 'listings', 'map']).isRequired,
  filterConfig: propTypes.filterConfig,
  sortConfig: propTypes.sortConfig,
  currentUser: propTypes.currentUser,
  isAuthenticated: bool.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
    searchMapListingIds,
    activeListingId,
  } = state.SearchPage;
  const { isAuthenticated } = state.Auth;
  const { currentUser } = state.user;
  const pageListings = getListingsById(state, currentPageResultIds);
  const mapListings = getListingsById(
    state,
    unionWith(currentPageResultIds, searchMapListingIds, (id1, id2) => id1.uuid === id2.uuid)
  );

  return {
    currentUser,
    listings: pageListings,
    mapListings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    activeListingId,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onSearchMapListings: searchParams => dispatch(searchMapListings(searchParams)),
  onActivateListing: listingId => dispatch(setActiveListing(listingId)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(SearchPageComponent);

export default SearchPage;
